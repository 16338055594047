import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Home from '../pagesComponents/home/HomePage';
import { Title1, Button, colors, margins, mobileThresholdPixels, fontSizes } from './Home/v2/StyledComponents';
import { trackEvent } from '../services/analytics';
import routes from '../Routes';

import iosImg from '../assets/footer/appstore.svg';
import androidImg from '../assets/footer/googleplay.svg';

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${margins.m};
`;

const Background = styled.div`
  background-color: ${colors.navyTransparent};
  min-height: 100vh;
  width: 100vw;
  z-index: 4;
  top: 0;
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  margin: ${margins.xl} 0px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.xl} ${margins.s};
    width: 100%;
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  padding: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${margins.m} ${margins.s};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${margins.m} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    justify-content: space-between;
    margin: ${margins.s} 0px;
  }
`;

const TextContainer = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 100;
  color: ${colors.navy};
  line-height: 1.5;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const A = styled.a`
  width: fit-content;
  text-decoration: none;
  color: inherit;
`;

const Icon = styled.img`
  width: 180px;
  cursor: pointer;
  margin: 0px ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90%;
    margin: 0px;
  }
`;


const hasClickedInsideModale = (evt) => {
  const flyoutElement = document.getElementById('modalSuccess');
  let targetElement = evt.target; // clicked element

  do {
    if (targetElement === flyoutElement) {
      return true;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  return false;
};

class Success extends React.Component {
  constructor() {
    super();
    this.state = { showModal: true };
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { showCard, order } = this.props;
    const orderId = order._id; // eslint-disable-line no-underscore-dangle
    const customerId = order.customer._id; // eslint-disable-line no-underscore-dangle
    return (
      <div>
        {this.state.showModal &&
          <Background onClick={e => !hasClickedInsideModale(e) && this.closeModal()}>
            <Modal id="modalSuccess">
              <Container>
                <StyledTitle1>votre commande a bien été enregistrée</StyledTitle1>
                <TextContainer>
                  Un mail  de confirmation vous sera envoyé dès acceptation de votre commande 😀<br /><br />
                  Nous faisons maintenant le maximum pour trouver le couturier qui
                  correspondra le mieux à vos besoins.<br />
                  D‘autres vêtements à ajouter ? C‘est possible, il suffit de demander
                  à votre couturier pendant le rdv.<br />
                  Vous serez débité du montant total de la commande à la suite de ce
                  premier rendez-vous.<br />
                </TextContainer>

                {showCard &&
                  <TextContainer>
                    Pour confirmer votre rendez-vous, vous pouvez enregistrer votre carte bancaire :
                  </TextContainer>}

                {showCard &&
                  <ButtonRow>
                    <Button
                      onClick={() => navigate(`${routes.Payment.url}/${orderId}/${customerId}`, {
                        state: { order } })}
                      noMargin
                    >{'enregistrer ma carte'}</Button>
                  </ButtonRow>
                }

                <TextContainer>
                  Pour suivre votre commande ou parrainer vos amis, vous pouvez vous connecter sur notre application :
                </TextContainer>

                <ButtonRow>
                  <A
                    target="_blank" rel="noreferrer noopener"
                    href={'https://itunes.apple.com/us/app/tilli/id1280578121'}
                    onClick={() => trackEvent('click', 'appStore_block-footer2')}
                  >
                    <Icon src={iosImg} alt={'Téléchager l\'apppli mobile pour iPhone'} />
                  </A>
                  <A
                    target="_blank" rel="noreferrer noopener"
                    href={'https://play.google.com/store/apps/details?id=fr.tilli.app'}
                    onClick={() => trackEvent('click', 'googlePlay_block-footer2')}
                  >
                    <Icon src={androidImg} alt={'Téléchager l\'apppli mobile pour Android'} />
                  </A>
                </ButtonRow>

                <Row>
                  <Button onClick={() => this.closeModal()}>
                    fermer
                  </Button>
                </Row>
              </Container>
            </Modal>
          </Background>
        }
        <Home />
      </div>
    );
  }
}

Success.propTypes = {
  showCard: PropTypes.bool.isRequired,
  order: PropTypes.shape({}),
};

Success.defaultProps = {
  order: { customer: {} },
};

export default Success;
