import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { ViewPager, Frame, Track, View } from 'react-view-pager';
import ProgressiveImage from 'react-progressive-image';

import withAppContext from '../../withAppContext';
import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import { margins, colors, fontWeight, mobileThresholdPixels, fontSizes,
  Row, RelativePosition, ButtonContainer, Button,
} from '../../components/Home/v2/StyledComponents';
import { HeaderPhoto as HeaderPhotoComponent } from '../../components/Home/homeStyledComponents';

import './frameStyle.css';

import header1 from '../../assets/headers/photo_header1_VotreCouturierDomicile.jpg';
import header2 from '../../assets/headers/photo_header2_EliseChalmin.jpg';
import header3 from '../../assets/headers/photo_header3_CousuDeBonnesIntentions.jpg';
import header1Low from '../../assets/headers/photo_header1_VotreCouturierDomicile.low.jpg';
import header2Low from '../../assets/headers/photo_header2_EliseChalmin.low.jpg';
import header3Low from '../../assets/headers/photo_header3_CousuDeBonnesIntentions.low.jpg';
import headerMobile1 from '../../assets/headers/photo_header_mobile1_VotreCouturierDomicile.jpg';
import headerMobile2 from '../../assets/headers/photo_header_mobile2_EliseChalmin.jpg';
import headerMobile3 from '../../assets/headers/photo_header_mobile3_CousuDeBonnesIntentions.jpg';
import headerMobile1Low
  from '../../assets/headers/photo_header_mobile1_VotreCouturierDomicile.low.jpg';
import headerMobile2Low
  from '../../assets/headers/photo_header_mobile2_EliseChalmin.low.jpg';
import headerMobile3Low
  from '../../assets/headers/photo_header_mobile3_CousuDeBonnesIntentions.low.jpg';
import arrowLeft from '../../assets/headers/arrow.white.left.png';
import arrowRight from '../../assets/headers/arrow.white.right.png';
import arrowEmpty from '../../assets/headers/arrow.empty.png';
import menSuitHeaderPhoto from '../../assets/photo-header-suit.jpg';
import motherHeaderPhoto from '../../assets/header-mere-retouche.jpg';
import headerPhotoMobile from '../../assets/headerV2-small.jpg';
import maduraHeaderPhoto from '../../assets/brand/Madura.jpg';
import apcHeaderPhoto from '../../assets/brand/APC.jpg';
import isabelMarantHeaderPhoto from '../../assets/brand/Isabel-Marant.jpg';
import carolinaRitzlerHeaderPhoto from '../../assets/brand/CarolinaRitzler.jpg';
import embroideryHeaderPhoto from '../../assets/header-broderie-retouche-veste-jeans.jpg';
import sezaneHeaderPhoto from '../../assets/brand/Sezane.jpg';
import bourrienneHeaderPhoto from '../../assets/brand/BourrienneParisX.jpg';

const headerImages = [
  { index: 0,
    img: typeof Image !== 'undefined' && new Image(),
    src: header1,
    mobileSrc: headerMobile1,
    thumbnailSrc: header1Low,
    mobileThumbnailSrc: headerMobile1Low,
    alt: 'Une chemise bleue avec un ruban de mesure posé dessus.',
    text: 'Votre couturier chez vous',
  },
  { index: 1,
    img: typeof Image !== 'undefined' && new Image(),
    src: header3,
    mobileSrc: headerMobile3,
    thumbnailSrc: header3Low,
    mobileThumbnailSrc: headerMobile3Low,
    alt: 'Une tilliste qui épingle un patalon pour la retouche.',
    text: 'Cousu de bonnes intentions',
  },
  { index: 2,
    img: typeof Image !== 'undefined' && new Image(),
    src: header2,
    mobileSrc: headerMobile2,
    thumbnailSrc: header2Low,
    mobileThumbnailSrc: headerMobile2Low,
    alt: 'Collab broderie avec Elise Chalmin',
  },
];

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const Container = styled.div`
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 20vh;
  }
`;

const SubContainer = styled.div`
  position: inherit;
  width: fit-content;
  text-align: center;
  margin: auto;
  top: -70px;
  margin-top: -113px;
  @media (max-width: ${mobileThresholdPixels}) {
    top: inherit;
    margin-top: -65px;
  }
`;

const StyledRow = styled(Row)`
  width: 100vw;
  justify-content: space-between;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
  }
`;

const ControlContainer = styled.div`
  ${props => props.fullSreen && 'width: 100vw'};
  ${props => props.right ? 'right: 0px' : 'left: 0px'};
  display: flex;
  align-items: center;
  z-index: 1;
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 30px;
  margin: ${margins.m};

  // fix image flickering in Chromium-based browsers
  -webkit-transform:translate3d(0,0,0);

  @media (max-width: ${mobileThresholdPixels}) {
    width: 14px;
  }
`;

const Img = styled.img`
  width: 100vw;
  height: 44vw;
  object-fit: cover;

  // fix image flickering in Chromium-based browsers
  -webkit-transform:translate3d(0,0,0);

  @media (max-width: ${mobileThresholdPixels}) {
    height: 80vh;
    max-height: 80vh;
  }
`;

const StyledButton = styled(Button)`
  padding: 22px 52px;
  margin: ${margins.s};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 18px 50px;
    margin-bottom: 0px;
  }
`;

const H2Container1 = styled.div`
  margin: auto;

  // fix text flickering in Chromium-based browsers
  -webkit-transform:translate3d(0,0,0);
`;

const H2 = styled.h2`
  color: ${colors.white};
  font-family: Gotham;
  margin: 0px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    line-Height: 0.5;
  }
`;

const Span1 = styled.span`
  font-size: 55px;
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
    line-height: 1;
    margin-bottom: ${fontSizes.s};
  }
`;

const Span2 = styled.span`
  font-family: Roboto;
  font-size: ${fontSizes.l};
  font-weight: ${fontWeight.regular};
  color: ${colors.white};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

class HeaderPhoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goNextSlide = this.goNextSlide.bind(this);
    this.goPreviousSlide = this.goPreviousSlide.bind(this);
  }

  getUnbrandedHeaderPhoto() {
    const { landingVersion } = this.props;
    if (landingVersion === 'menSuitVersion') {
      return menSuitHeaderPhoto;
    } else if (landingVersion === 'motherVersion') {
      return motherHeaderPhoto;
    } else if (landingVersion === 'embroidery') {
      return embroideryHeaderPhoto;
    }
    return this.props.context.isMobile ? headerPhotoMobile : header1;
  }

  getHeaderPhoto() {
    switch (process.env.GATSBY_BRAND) {
      case 'MADURA':
        return maduraHeaderPhoto;
      case 'APC':
        return apcHeaderPhoto;
      case 'ISABEL_MARANT':
        return isabelMarantHeaderPhoto;
      case 'CAROLINA_RITZLER':
        return carolinaRitzlerHeaderPhoto;
      case 'SEZANE':
        return sezaneHeaderPhoto;
      case 'BOURRIENNE':
        return bourrienneHeaderPhoto;
      default:
        return this.getUnbrandedHeaderPhoto();
    }
  }

  goPreviousSlide() {
    this.track.prev();
  }

  goNextSlide() {
    this.track.next();
  }

  render() {
    const { isMobile } = this.props.context;

    if (process.env.GATSBY_BRAND) {
      return (
        <RelativePosition>
          <HeaderPhotoComponent
            src={this.getHeaderPhoto()}
            alt="Cliente portant une jupe retouchée"
          />
          <SubContainer>
            <Link
              to={routesMap.OrderPage.url}
              onClick={() => trackEvent('click', 'reserver_block-home')}
            >
              <ButtonContainer margin={'0px'}>
                <StyledButton navy>Réserver</StyledButton>
              </ButtonContainer>
            </Link>
          </SubContainer>
        </RelativePosition>
      );
    }

    return (
      <RelativePosition>
        <ViewPager tag="main" style={viewPagerStyle}>
          <Frame className="frame">
            <Track
              ref={(c) => { this.track = c; }}
              viewsToShow={1}
              className="track"
            >
              {headerImages.map((headerImage, index) =>
                <View className="view" key={headerImage.index} >
                  <ProgressiveImage
                    src={!isMobile ? headerImage.src : headerImage.mobileSrc}
                    placeholder={!isMobile ? headerImage.thumbnailSrc : headerImage.mobileThumbnailSrc}
                  >
                    {src => <Img alt={headerImage.alt} src={src} />}
                  </ProgressiveImage>
                  <Container>
                    <StyledRow alignItems={'center'}>
                      <ControlContainer>
                        <ControlImg
                          alt="Photo précédente de retoucherie"
                          src={index !== 0 ? arrowLeft : arrowEmpty}
                          onClick={this.goPreviousSlide}
                        />
                      </ControlContainer>
                      {headerImage.text &&
                        <H2Container1>
                          <H2>
                            <Span1>{headerImage.text}</Span1><br />
                            {isMobile && <br />}
                            <Span2>7 jours sur 7, de 9H00 à 22H00</Span2>
                          </H2>
                        </H2Container1>
                      }
                      <ControlContainer right>
                        <ControlImg
                          alt="Photo suivante d'une maison de couture"
                          src={index !== headerImages.length - 1 ? arrowRight : arrowEmpty}
                          onClick={this.goNextSlide}
                        />
                      </ControlContainer>
                    </StyledRow>
                  </Container>
                </View>,
              )}
            </Track>
          </Frame>
        </ViewPager>
        <SubContainer>
          <Span2>Je réserve mon couturier pour mes retouches !</Span2>
          <Link
            to={routesMap.OrderPage.url}
            onClick={() => trackEvent('click', 'reserver_block-home')}
          >
            <ButtonContainer margin={'0px'}>
              <StyledButton navy>Prendre rdv</StyledButton>
            </ButtonContainer>
          </Link>
        </SubContainer>
      </RelativePosition>
    );
  }
}

HeaderPhoto.propTypes = {
  landingVersion: PropTypes.string,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

HeaderPhoto.defaultProps = {
  landingVersion: null,
};

export default withAppContext(HeaderPhoto);
