import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import isMobile from '../../services/isMobile';
import Dots from '../../components/Home/v2/Dots';
import {
  colors, mobileThresholdPixels, fontSizes, fontWeight, margins,
  ContainerBlock, Title2, Subtitle2, ButtonContainer, Button, Img, Column, RelativePosition,
} from '../../components/Home/v2/StyledComponents';
import img1 from '../../assets/whyTilli/PhotoWhyTilli1.jpg';
import img2 from '../../assets/whyTilli/PhotoWhyTilli2.jpg';
import img3 from '../../assets/whyTilli/PhotoWhyTilli3.jpg';
import arrowRightUp from '../../assets/whyTilli/L.reversed.white.svg';
import arrowLeftDown from '../../assets/whyTilli/L.white.svg';

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const BlockContainer = styled.div`
  display: flex;
  align-items: stretch;
  align-content: center;
  flex: 1 1 auto;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px;
    flex-wrap: wrap;
  }
`;


const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  ${props => !props.last ? `margin: 0px ${margins.m} 0px 0px` : ''};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const SubTitle = styled.h2`
  color: ${colors.navy};
  font-size: ${fontSizes.xl};
  font-family: Gotham;
  font-weight: ${fontWeight.medium};
  text-transform: uppercase;
  text-align: center;
  margin: ${margins.s} 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.xl};
    margin: ${props => !props.last ? `${margins.s} 0px;` : `${margins.s} 0px 0px 0px;`};

  }
`;

const ArrowImg = styled.img`
  width: 90%;
  ${props => props.middle && 'transform : rotate(90deg)'};
`;

const ArrowsContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftSide = styled.div`
  display: flex;
  align-items: flex-end;
  height: 50%;
  width: 25%;
`;

const RightSide = styled.div`
  height: 50%;
  width: 25%;
`;

const WhyTilli = () => (
  <ContainerBlock>
    <Column justifyContent={'center'} alignItems={'center'}>
      <Title2>Pourquoi Tilli</Title2>
      <Dots />
      <Subtitle2>Les retouches du quotidien <br /> et des grandes occasions.</Subtitle2>
    </Column>
    <SubContainer>
      <BlockContainer>
        <Block>
          <RelativePosition>
            <Img src={img1} alt="ourlet de pantalon à 12€" />
            <ArrowsContainer>
              <LeftSide>
                <ArrowImg left src={arrowLeftDown} alt="exemple de retouche" />
              </LeftSide>
              <RightSide>
                <ArrowImg right src={arrowRightUp} alt="Un ourlet sur jeans" />
              </RightSide>
            </ArrowsContainer>
          </RelativePosition>
          <SubTitle>{'L\'ourlet'} <br /> 12€</SubTitle>
        </Block>
        <Block>
          <RelativePosition>
            <Img src={img2} alt="la broderie sur pull à partir de 15€" />
            <ArrowsContainer>
              <RightSide>
                <ArrowImg middle src={arrowLeftDown} alt="Exemple de transformation" />
              </RightSide>
              <LeftSide>
                <ArrowImg middle src={arrowRightUp} alt="Une broderie fait main sur un t-shirt" />
              </LeftSide>
            </ArrowsContainer>
          </RelativePosition>
          <SubTitle>La broderie <br /> 15€</SubTitle>
        </Block>
        <Block last >
          <RelativePosition>
            <Img src={img3} alt="la transformation en dos nu à 40€" />
            <ArrowsContainer>
              <LeftSide>
                <ArrowImg src={arrowLeftDown} alt="Exemple de retoucherie" />
              </LeftSide>
              <RightSide>
                <ArrowImg src={arrowRightUp} alt="Transformation d'une robe avec ouverture du dos" />
              </RightSide>
            </ArrowsContainer>
          </RelativePosition>
          <SubTitle last>Le dos nu <br /> 40€</SubTitle>
        </Block>
      </BlockContainer>
      <Link
        to={routesMap.OrderPage.url}
        onClick={() => trackEvent('click', 'commande_block-pourquoi-tilli')}
      >
        <ButtonContainer margin={isMobile() ? `0px 0px ${margins.s} 0px` : undefined}>
          <Button>Commander</Button>
        </ButtonContainer>
      </Link>
    </SubContainer>
  </ContainerBlock>
);

export default WhyTilli;
