import React from 'react';
import Home from '../../components/Home/Home';
import Layout from '../../layouts/index';

class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const props = this.props;
    return (
      <Layout routeSlug="HomePage">
        <Home {...props} />
      </Layout>
    );
  }
}

export default HomePage;
