import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import Dots from '../../components/Home/v2/Dots';
import { trackEvent } from '../../services/analytics';
import isMobile from '../../services/isMobile';
import {
  colors, fontSizes, fontWeight, margins, mobileThresholdPixels,
  Title2, Title3, Subtitle2, ButtonContainer, Button, ContainerBlock, Column, Img, EmphText,
} from '../../components/Home/v2/StyledComponents';

import img1 from '../../assets/howItWorks/PhotoHowItworks1.jpg';
import img2 from '../../assets/howItWorks/PhotoHowItworks2.jpg';
import img3 from '../../assets/howItWorks/PhotoHowItworks3.jpg';

const howItWorksContent = [
  { index: 0,
    title: isMobile() ? '1. Commande' : 'Commande',
    emphText: (
      <span>
        Commandez votre retouche <br /> sur tilli.fr ou sur notre application.
      </span>
    ),
    text: (
      <span>
        Vous commandez votre retouche sur notre site ou sur l’application mobile Tilli.
        Selon les types de retouches nécessaires, les différentes matières à travailler
        et leur disponibilité, Tilli affecte l’un de ses couturiers professionnels.
      </span>
    ),
    img: img1,
    altImg: 'Un téléphone connecté sur le site de Tilli.',
    button: (<Button>Commander</Button>),
  },
  { index: 1,
    title: isMobile() ? '2. Rendez-vous' : 'Rendez-vous',
    emphText: (
      <span>
        Votre couturier se rend chez vous <br />à l’heure de votre choix.
      </span>
    ),
    text: (
      <span>
        Votre couturier vient chez vous à date et heure de votre choix pour prendre
        vos mesures et vous conseiller. Vous avez la possibilité de modifier ou compléter
        votre commande pendant votre rendez-vous.
      </span>
    ),
    img: img2,
    altImg: 'Une tilliste qui ajuste la robe d\'une cliente.',
    button: (<Button>Prendre rendez-vous</Button>),
  },
  { index: 2,
    title: isMobile() ? '3. Livraison' : 'Livraison',
    emphText: (
      <span>
        Une fois prêtes, vos retouches <br />sont livrées chez vous pour essayage.
      </span>
    ),
    text: (
      <span>
        Tilli se charge de faire livrer vos vêtements retouchés au lieu convenu
        en premier rendez-vous, à date et heure préalablement fixées.
      </span>
    ),
    img: img3,
    altImg: 'Un pantalon rendu au client après une retouche faite par Tilli.',
    button: (<Button>Commander</Button>),
  },
];

const SubContainer = styled.div`
  color: ${colors.navy};
  display: flex;
  align-items: stretch;
  align-content: center;
  margin: ${margins.l} 0px;
  flex-direction: ${props => props.imgFirst ? 'row' : 'row-reverse'};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: ${margins.s} 0px;
  }
`;

const ImgContainer = styled.div`
  flex: 1;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const StyledButtonContainer = styled(ButtonContainer)`
  @media (max-width: ${mobileThresholdPixels}) {
    ${props => props.last && `margin-bottom: ${margins.s};`}
  }
`;

const TextMainContainer = styled.div`
  flex: 1;
  padding: 0px;
  ${props => props.imgFirst ?
    `padding-left: ${margins.l}` :
    `padding-right: ${margins.l}`};
  width: calc(50% - ${margins.l});

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: 0px;
  }
`;

const TextContainer = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-weight: ${fontWeight.light};
  color: ${colors.navy};
  line-height: 22px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const HowItWorks = () => (
  <ContainerBlock id="HowItWorks">
    <Column justifyContent={'center'} alignItems={'center'}>
      <Title2>Comment ça marche</Title2>
      <Dots />
      <Subtitle2>
        {!isMobile() ?
          <span>Vos retouches livrées chez vous <br /> en trois étapes.</span>
          : <span>Vos retouches livrées <br />chez vous en trois étapes.</span>
        }
      </Subtitle2>
    </Column>
    {howItWorksContent.map(content =>
      <SubContainer imgFirst={(content.index % 2) === 0} key={content.index}>
        <ImgContainer>
          <Img src={content.img} alt={content.altImg} />
        </ImgContainer>
        <TextMainContainer imgFirst={(content.index % 2) === 0}>
          <Title3>{content.title}</Title3>
          <EmphText>{content.emphText}</EmphText>
          <TextContainer>{content.text}</TextContainer>
          <Link
            to={routesMap.OrderPage.url}
            onClick={() => trackEvent('click', `${content.button}_block-comment-ca-marche`)}
          >
            <StyledButtonContainer last={content.index === howItWorksContent.length - 1}>
              {content.button}
            </StyledButtonContainer>
          </Link>
        </TextMainContainer>
      </SubContainer>,
    )}
  </ContainerBlock>
);

export default HowItWorks;
